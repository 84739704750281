import React from 'react';
import styled from 'styled-components';

import LinkButton from '../components/Buttons/LinkButton';

import HeroBackground from '../assets/img/about-hero-background.jpg';
import THBackground from '../assets/img/th-product-background.png';
import ImmersiveBackground from '../assets/img/immersive-product-background.png';
import CustomARImage from '../assets/img/custom-ar.png';
import CustomVRImage from '../assets/img/custom-vr.png';
import CustomGamesImage from '../assets/img/custom-games.png';

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.4rem;
  background: url(${HeroBackground}) center / cover no-repeat;
  border-radius: var(--border-radius-surface) var(--border-radius-surface) 0 0;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60rem;
  width: 100%;
  max-width: var(--page-content-width);
`;

const About = styled.div`
  position: relative;
  margin-top: -2rem;
  padding: 12rem 2.4rem;
  background: var(--color-surface-light);
  border-radius: var(--border-radius-surface);
`;

const CompanySection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto 9.6rem;
  h2 {
    margin-bottom: 6.4rem;
    color: var(--color-heading-dark);
  }
  a {
    margin-right: auto;
  }
`;

const ComopanyDescriptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.4rem 8rem;
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const ProductsSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto 9.6rem;
  h2 {
    margin-bottom: 6.4rem;
    color: var(--color-heading-dark);
  }
`;

const ProductsWrapper = styled.div`
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 4.8rem;
    border-radius: var(--border-radius-surface);
    box-shadow: 0px 38px 80px rgba(0, 0, 0, 0.07), 0px 11.4559px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 4.75819px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.72094px 3.62304px rgba(0, 0, 0, 0.0243888);
  }
  p {
    margin-bottom: var(--margin-text-bottom);
    color: #104249;
  }
  a {
    position: relative;
    margin-left: auto;
    font-weight: 700;
    color: #0B393F;
    transition: opacity 0.15s ease-in-out;
    ::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -0.2rem;
      width: 100%;
      height: 0.2rem;
      background: #0B393F;
      transform: translateY(0);
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }
    :hover ::after {
      opacity: 1;
      transform: translateY(0.2rem);
    }
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const TreasureHunt = styled.div`
  margin-right: 4.8rem;
  background: url(${THBackground}) center / cover no-repeat #ffffff;
  h3 {
    color: #986017;
  }
  @media only screen and (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
`;

const Immersive = styled.div`
  background: url(${ImmersiveBackground}) center / cover no-repeat #ffffff;
  h3 {
    color: #24729F;
  }
`;

const CustomDevSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: var(--page-content-width);
  margin: 0 auto;
  h2 {
    margin-bottom: 6.4rem;
    color: var(--color-heading-dark);
  }
`;

const CustomAppsWrapper = styled.div`
  display: flex;
  a {
    flex: 1;
    :not(:last-child) {
      margin-right: 4.8rem;
    }
    :hover div {
      background: var(--color-green);
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 3.2rem;
    background: #fff;
    border-radius: var(--border-radius-surface);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: background 0.15s ease-in-out;
    h3 {
      margin: 0;
    }
  }
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    a {
      width: 100%;
      max-width: 40rem;
      align-self: center;
      :not(:last-child) {
        margin-right: 0;
        margin-bottom: 2.4rem;
      }
    }
  }
`;

const AboutUsPage = () => {
  return (
    <>
      <Hero>
        <HeroContent>
          <h1>About us</h1>
        </HeroContent>
      </Hero>
      
      <About>
        <CompanySection>
          <div className='caption'>Who are we</div>
          <h2>The company behind veerXR</h2>
          <ComopanyDescriptionWrapper>
            <p>ARVRtech is a global company focused on developing AR & VR solutions that drive the world towards a better future. Our software can be used across a range of verticals: manufacturing, business, education, healthcare, marketing, art, tourism, and more.</p>
            <p>Our team consists of highly educated, highly experienced AR & VR experts who love to try out new things and discover better solutions for known problems.</p>
            <p>Emerging technologies fascinate us. We develop both scientific and commercial augmented reality and virtual reality projects for governments, universities, and businesses across the globe.</p>
            <LinkButton href='https://arvrtech.eu/about-us/' backgroundColor='var(--color-green)' textColor='var(--color-body-dark)' >Find out more about us</LinkButton>
          </ComopanyDescriptionWrapper>
        </CompanySection>

        <ProductsSection>
          <div className='caption'>What else do we make</div>
          <h2>Here are some of the products in our offer</h2>
          <ProductsWrapper>
            <TreasureHunt>
              <h3>AR Treasure Hunt</h3>
              <p>An AR application that allows you to implement a scavenger hunt game enhanced by augmented reality.</p>
              <a href="https://arvrtech.eu/treasure-hunt/">Learn more</a>
            </TreasureHunt>
            <Immersive>
              <h3>Immersive4Learning</h3>
              <p>Immersive VR learning software that utilizes cutting-edge technology to personalize the learning experience.</p>
              <a href="https://arvrtech.eu/immersive-4-learning/">Learn more</a>
            </Immersive>
          </ProductsWrapper>
        </ProductsSection>

        <CustomDevSection>
          <div className='caption'>Custom development</div>
          <h2>If you need a custom AR or VR solution for your business,<br/>feel free to explore our services</h2>
          <CustomAppsWrapper>
            <a href="https://arvrtech.eu/custom-augmented-reality-applications/">
              <div>
                <h3>Custom AR</h3>
                <img src={CustomARImage} />
              </div>
            </a>
            <a href="https://arvrtech.eu/custom-virtual-reality-applications/">
              <div>
                <h3>Custom VR</h3>
                <img src={CustomVRImage} />
              </div>
            </a>
            <a href="https://arvrtech.eu/custom-serious-games/">
              <div>
                <h3>Custom<br/>Serious<br/>Games</h3>
                <img src={CustomGamesImage} />
              </div>
            </a>
          </CustomAppsWrapper>
        </CustomDevSection>
      </About>
    </>
  )
}

export default AboutUsPage;
